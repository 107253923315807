import React, { useState } from 'react';
import qs from 'qs';

import { Button } from '../button/Button';
import clsx from 'clsx';
import { Form } from '../form/Form';
import { FormField } from '../form/FormField';
import { useAuthService } from '@sus-core/state/xstate/auth/useAuthService';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby-link';
import { Icons } from '../icon/Icons';
import { Modal } from '../modal/Modal';
import { restApi } from '@sus-core/api/client/sus-rest-client';
import { notifyError, notifySuccess } from '@sus-core/utils/notify';
import { emailValidation, requiredValidation } from '../form/validationRules';
import { useI18n } from '@sus-core/i18n/useI18n';

export function LoginForm({ className }: { className?: string }) {
  const { login, logout, state, loading } = useAuthService();
  const { t } = useI18n();
  const { search } = useLocation();

  const { to = '/' } = qs.parse(search ? search.replace('?', '') : search);

  const handleSubmit = (values: Record<string, string>) => {
    state.value === 'unauthorized'
      ? login(values['email'], values['password'])
          .then(() => {
            to && navigate(to as string);
          })
          .catch(e => console.error(e))
      : logout();
  };

  return (
    <div className={clsx('b-box', className)}>
      <h3>Registrierter Kunde</h3>
      <p>
        Wenn Sie bei uns ein Benutzerkonto besitzen,
        <br />
        melden Sie sich bitte an.
      </p>
      <Form
        className={clsx('flex flex-col gap-y-2')}
        onSubmit={handleSubmit}
        render={({ formState }) => {
          return (
            <>
              <FormField
                name="email"
                label="E-Mail"
                labelClass=""
                required={requiredValidation}
                pattern={emailValidation}>
                <input className="flex-1" type="email" />
              </FormField>
              <FormField
                name="password"
                label="Passwort"
                className="mb-6"
                required={requiredValidation}>
                <input className="flex-1" type="password" required />
              </FormField>
              <Button
                disabled={!formState.isDirty || !formState.isValid}
                loading={loading}
                variant="primary"
                size={1}
                type="submit">
                Anmelden
              </Button>
            </>
          );
        }}
      />
      <div className="flex justify-center mt-8">
        <RequestPasswordReset />
      </div>
      <div>
        {state.context.error && (
          <p className="text-danger mt-4 flex">
            <Icons.Danger />
            {t(state.context.error)}
          </p>
        )}
      </div>
    </div>
  );
}

function RequestPasswordReset() {
  const [isOpen, setOpen] = useState(false);
  const handleSubmit = (email: string) =>
    restApi
      .requestPasswordReset(email)
      .then(result => {
        notifySuccess(
          'Anfrage erfolgreich. Sie sollten in den nächsten Minuten eine Email erhalten.',
          5000
        );
        setOpen(false);
      })
      .catch(err => notifyError('Bei der Anfrage ist ein Fehler aufgetreten.'));
  return (
    <>
      <Button
        className="underline"
        variant="white"
        size={2}
        onClick={() => setOpen(true)}>
        Passwort vergessen?
      </Button>
      <Modal title="Password zurücksetzen" isOpen={isOpen}>
        <p>
          Bitte tragen Sie hier die E-Mail Adresse Ihres Kontos ein. Sie
          erhalten dann eine E-Mail mit Link zum Zurücksetzen ihres Passwortes.
        </p>
        <RequestPasswordResetForm
          onCancel={() => setOpen(false)}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
}

function RequestPasswordResetForm({
  onCancel,
  onSubmit,
}: {
  onCancel?: () => void;
  onSubmit?: (email: string) => void;
}) {
  return (
    <Form
      onSubmit={values => onSubmit?.(values['email'])}
      render={({ formState }) => {
        return (
          <>
            <FormField
              name="email"
              label="E-Mail"
              required={requiredValidation}>
              <input className="flex-1" type="email" />
            </FormField>
            <div className="mt-4 flex justify-between">
              <Button
                size={2}
                variant="bordered"
                onClick={e => {
                  e.preventDefault();
                  onCancel?.();
                }}>
                Abbrechen
              </Button>
              <Button
                disabled={!formState.isDirty || !formState.isValid}
                type="submit"
                size={2}
                variant="primary">
                Senden
              </Button>
            </div>
          </>
        );
      }}
    />
  );
}
