import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';

import Layout from '@sus-core/components/scaffolding/Layout';
import { LoginForm } from '@sus-core/components/user/LoginForm';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import { PageProvider } from '@sus-core/hooks/page/PageContext';

import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';

export default function LoginPage({ location, pageContext }: SusPageProps) {
  return (
    <PageProvider
      data={{
        pageType: 'Login',
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <Layout
        location={location}
        meta={{ title: 'Schrank und Stuhl - Anmelden' }}
        currentPageName="Anmelden">
        <div
          className={clsx(
            'flex flex-col-reverse md:flex-row gap-4',
            PAGE_CONTAINER_CSS
          )}>
          <div className="b-box flex-1 flex flex-col">
            <h3>Ich bin Neukunde</h3>
            <p>
              Wenn Sie in unserem Shop ein Benutzerkonto einrichten, werden Sie
              schneller durch den Bestellvorgang geführt, können mehrere
              Versandadressen speichern, Ihren bisherigen Bestellablauf
              verfolgen und vieles mehr.
            </p>
            <Link className="btn-primary btn-s-1 " to="/konto-anlegen">
              Jetzt registrieren
            </Link>
          </div>
          <LoginForm className="flex-1" />
        </div>
      </Layout>
    </PageProvider>
  );
}
